export default {
  NoData: "無數據",
  networkdelay: "網絡延遲,請稍後再試",
  index: {
    fleetrevenue: "當前艦隊收益",
    Issue: "第{number}期",
    starship: "星艦",
    Notturnedon: "未開啟",
    remainingstarships: "剩餘星艦",
    Starshipssold: "已售星艦",
    Totalstarships: "星艦總量",
    launchpreparation: "發射準備",
    mystarship: "我的星艦",
    inprogress: "進行中",
    ended: "已結束",
    Refunded: "已退款",
    unknownstatus: "未知狀態",
    Launchsuccessful: "發射成功",
    launchfailed: "發射失敗",
    Exceedingthemaximumlimit: "超出最大購買數量",
    Outoflevel: "超出等級",
    Donationcardexpired: "捐贈卡過期",
    Notwithinparticipationtime: "不在參與時間內",
    Exceededmaximumsupply: "超過最大供應量",
    Exceededthemaximumnumberofminers: "超過最大礦機數量",
  },
  car: {
    Numberofstarshipssold: "售出星艦數量",
    Numberofstarshipslaunched: "發射星艦數量",
    Mystarshipnumber: "我的星艦編號",
  },
  deta: {
    starshiptrajectory: "星艦軌跡",
    Successfullylaunched: "成功發射",
    launchtime: "發射時間",
    Purchaselimit: "限購數量",
    ships: "艘",
    Successstarshipnumber: "成功星艦編號",
    myearnings: "我的收益",
    fleetrevenue: "艦隊收益",
    Availableforcollection: "可領取",
    Notinvolved: "未參與",
    staticincome: "靜態收益",
    Numberofrecyclablestarships: "可回收星艦數量",
    recyclable: "可回收",
    Successfullylaunchedaccount: "發射成功賬戶",
    Receivefleetbenefits: "領取艦隊收益",
    Recoverstarship: "回收星艦",
    Receivedsuccessfully: "領取成功",
    Recyclingsuccessful: "回收成功",
    Received: "已領取",
  },
  min: {
    Miningoutputcoefficient: "挖礦產出係數",
    Completeddestruction: "已完成銷毀",
    Myminingmachine: "我的礦機",
    Miningmachinenumber: "{number}號礦機",
    Mining: "挖礦中",
    Out: "已出局",
    MiningtotalMarsvalue: "挖礦總火星值",
    MiningmachinetotalMarsvalue: "礦機剩餘火星值",
    Currentlyavailableforcollection: "當前可領取{name}",
    Estimatedfuelcost: "預估燃油費",
    MinedSTAS: "已挖掘STAS",
    Minecoolingperiod: "出礦冷卻期",
    Withdrawearnings: "提取收益",
    Extractionsuccessful: "提取成功",
  },
  my: {
    Bindcaptainaddress: "綁定艦長地址",
    binding: "綁定",
    Myfleetdirectlypushesthenumberofpeople: "我的艦隊直推人數",
    Invitefriends: "邀請好友",
    Expired: "已過期",
    level: "級",
    maturity: "到期",
    Participateinhistory: "參與歷史",
    Buyitnow: "立即購買",
    Copiedsuccessfully: "複製成功",
    Copyfailed: "複製失敗",
    Pleaseenterthecorrectwalletaddress: "請輸入正確的錢包地址",
    Bindingsuccessful: "綁定成功",
    Purchasesuccessful: "購買成功",
  },
};
