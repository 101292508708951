export default {
  NoData: "데이터 없음",
  networkdelay: "네트워크 지연, 나중에 시도하세요",
  index: {
    fleetrevenue: "함대 수익",
    Issue: "{number}라운드",
    starship: "스타쉽",
    Notturnedon: "비활성화",
    remainingstarships: "남은 스타쉽",
    Starshipssold: "판매된 스타쉽",
    Totalstarships: "총 스타쉽",
    launchpreparation: "출시 준비",
    mystarship: "내 스타쉽",
    inprogress: "진행 중",
    ended: "종료됨",
    Refunded: "환불됨",
    unknownstatus: "알 수 없는 상태",
    Launchsuccessful: "출시 성공",
    launchfailed: "출시 실패",
    Exceedingthemaximumlimit: "최대 한도 초과",
    Outoflevel: "레벨 초과",
    Donationcardexpired: "기부 카드 만료",
    Notwithinparticipationtime: "참여 시간 외",
    Exceededmaximumsupply: "최대 공급 초과",
    Exceededthemaximumnumberofminers: "최대 채굴기 초과",
  },
  car: {
    Numberofstarshipssold: "판매된 스타쉽 수",
    Numberofstarshipslaunched: "출시된 스타쉽 수",
    Mystarshipnumber: "내 스타쉽 번호",
  },
  deta: {
    starshiptrajectory: "스타쉽 경로",
    Successfullylaunched: "출시 성공",
    launchtime: "출시 시간",
    Purchaselimit: "구매 제한",
    ships: "대",
    Successstarshipnumber: "성공한 스타쉽 번호",
    myearnings: "내 수익",
    fleetrevenue: "함대 수익",
    Availableforcollection: "수령 가능",
    Notinvolved: "참여하지 않음",
    staticincome: "정적 수익",
    Numberofrecyclablestarships: "회수 가능한 스타쉽 수",
    recyclable: "회수 가능",
    Successfullylaunchedaccount: "출시 성공 계정",
    Receivefleetbenefits: "함대 보상 수령",
    Recoverstarship: "스타쉽 회수",
    Receivedsuccessfully: "수령 성공",
    Recyclingsuccessful: "회수 성공",
    Received: "수령 완료",
  },
  min: {
    Miningoutputcoefficient: "채굴 출력 계수",
    Completeddestruction: "파괴 완료",
    Myminingmachine: "내 채굴기",
    Miningmachinenumber: "{number}번 채굴기",
    Mining: "채굴 중",
    Out: "퇴출됨",
    MiningtotalMarsvalue: "총 채굴 화성 가치",
    MiningmachinetotalMarsvalue: "남은 채굴 화성 가치",
    Currentlyavailableforcollection: "현재 수령 가능 {name}",
    Estimatedfuelcost: "예상 연료비",
    MinedSTAS: "채굴된 STAS",
    Minecoolingperiod: "쿨다운 기간",
    Withdrawearnings: "수익 인출",
    Extractionsuccessful: "인출 성공",
  },
  my: {
    Bindcaptainaddress: "캡틴 주소 연결",
    binding: "연결",
    Myfleetdirectlypushesthenumberofpeople: "직접 추천 수",
    Invitefriends: "친구 초대",
    Expired: "만료됨",
    level: "레벨",
    maturity: "만기",
    Participateinhistory: "참여 기록",
    Buyitnow: "즉시 구매",
    Copiedsuccessfully: "복사 성공",
    Copyfailed: "복사 실패",
    Pleaseenterthecorrectwalletaddress: "유효한 지갑 주소를 입력하세요",
    Bindingsuccessful: "연결 성공",
    Purchasesuccessful: "구매 성공",
  },
};
