import { createRouter, createWebHashHistory } from "vue-router";
import IndexView from "../views/IndexView.vue";

const routes = [
  {
    path: "/:address?",
    name: "home",
    component: IndexView,
    meta: { title: "Starship-Planet on Chain" }, // 设置 meta.title
  },
  {
    path: "/Timeline",
    name: "Timeline",
    component: () => import("@/views/TimelineView.vue"),
    meta: { title: "MiningHistory" }, // 设置 meta.title
  },
  {
    path: "/Detai/:id",
    name: "Detai",
    component: () => import("@/views/DetailView.vue"),
    meta: { title: "MiningDetails" }, // 设置 meta.title
  },
  {
    path: "/Mining",
    name: "Mining",
    component: () => import("@/views/MiningView.vue"),
    meta: { title: "CryptoMining" }, // 设置 meta.title
  },
  {
    path: "/my",
    name: "my",
    component: () => import("@/views/MyView.vue"),
    meta: { title: "my" }, // 设置 meta.title
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const title = to.meta.title || "Default Title";
  document.title = title;
  next();
});
export default router;
