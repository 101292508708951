export default {
  NoData: "No Data",
  networkdelay: "Network delay, try again later",
  index: {
    fleetrevenue: "Fleet Revenue",
    Issue: "Round {number}",
    starship: "Starship",
    Notturnedon: "Not Active",
    remainingstarships: "Remaining Starships",
    Starshipssold: "Starships Sold",
    Totalstarships: "Total Starships",
    launchpreparation: "Launch Prep",
    mystarship: "My Starship",
    inprogress: "Ongoing",
    ended: "Ended",
    Refunded: "Refunded",
    unknownstatus: "Unknown Status",
    Launchsuccessful: "Launch Successful",
    launchfailed: "Launch Failed",
    Exceedingthemaximumlimit: "Exceeds Limit",
    Outoflevel: "Out of Level",
    Donationcardexpired: "Card Expired",
    Notwithinparticipationtime: "Outside Timeframe",
    Exceededmaximumsupply: "Max Supply Exceeded",
    Exceededthemaximumnumberofminers: "Max Miners Exceeded",
  },
  car: {
    Numberofstarshipssold: "Starships Sold",
    Numberofstarshipslaunched: "Starships Launched",
    Mystarshipnumber: "My Starship ID",
  },
  deta: {
    starshiptrajectory: "Starship Path",
    Successfullylaunched: "Launch Successful",
    launchtime: "Launch Time",
    Purchaselimit: "Purchase Limit",
    ships: "Ships",
    Successstarshipnumber: "Successful Starship ID",
    myearnings: "My Earnings",
    fleetrevenue: "Fleet Revenue",
    Availableforcollection: "Claimable",
    Notinvolved: "Not Participated",
    staticincome: "Passive Income",
    Numberofrecyclablestarships: "Recyclable Starships",
    recyclable: "Recyclable",
    Successfullylaunchedaccount: "Launch Success Account",
    Receivefleetbenefits: "Claim Fleet Rewards",
    Recoverstarship: "Recover Starship",
    Receivedsuccessfully: "Claim Successful",
    Recyclingsuccessful: "Recycle Successful",
    Received: "Claimed",
  },
  min: {
    Miningoutputcoefficient: "Mining Coefficient",
    Completeddestruction: "Destroyed",
    Myminingmachine: "My Miner",
    Miningmachinenumber: "Miner #{number}",
    Mining: "Mining",
    Out: "Exited",
    MiningtotalMarsvalue: "Total Mars Value Mined",
    MiningmachinetotalMarsvalue: "Miner Mars Value Left",
    Currentlyavailableforcollection: "Claimable {name}",
    Estimatedfuelcost: "Est. Gas Fee",
    MinedSTAS: "STAS Mined",
    Minecoolingperiod: "Cooldown Period",
    Withdrawearnings: "Withdraw Earnings",
    Extractionsuccessful: "Withdraw Successful",
  },
  my: {
    Bindcaptainaddress: "Bind Captain Address",
    binding: "Bind",
    Myfleetdirectlypushesthenumberofpeople: "Direct Referrals",
    Invitefriends: "Invite Friends",
    Expired: "Expired",
    level: "Level",
    maturity: "Expires",
    Participateinhistory: "Participation History",
    Buyitnow: "Buy Now",
    Copiedsuccessfully: "Copied",
    Copyfailed: "Copy Failed",
    Pleaseenterthecorrectwalletaddress: "Enter Valid Wallet Address",
    Bindingsuccessful: "Bind Successful",
    Purchasesuccessful: "Purchase Successful",
  },
};
