<template>
  <div class="Countdown">
    <div>
      <span>{{ pad(hours) }}</span>
    </div>
    <span>:</span>
    <div>
      <span>{{ pad(minutes) }}</span>
    </div>
    <span>:</span>
    <div>
      <span>{{ pad(seconds) }}</span>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from "vue";

const props = defineProps({
  message: {
    type: Number,
    required: true,
  },
});
let deadline = 0;
let timer = null;
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);
const pad = (num) => (num < 10 ? `0${num}` : num);
const updateTime = () => {
  const remainingTime = deadline - Date.now();
  if (remainingTime <= 0) {
    clearInterval(timer);
    hours.value = minutes.value = seconds.value = 0;
    return;
  }
  const totalSeconds = Math.floor(remainingTime / 1000);
  hours.value = Math.floor(totalSeconds / 3600);
  minutes.value = Math.floor((totalSeconds % 3600) / 60);
  seconds.value = totalSeconds % 60;
};

const startCountdown = (time) => {
  deadline = time;
  if (timer) {
    clearInterval(timer);
  }
  updateTime();
  timer = setInterval(() => {
    updateTime();
  }, 1000);
};

onBeforeUnmount(() => {
  if (timer) {
    clearInterval(timer);
  }
});

defineExpose({
  startCountdown,
});

onMounted(() => {
  startCountdown(props.message);
});
</script>
<style lang="scss">
.Countdown {
  height: 0.22rem;
  display: flex;
  flex-direction: row;
  font-size: 0.15rem;
  font-weight: 500;
  div {
    padding: 0 0.04rem;
    border-radius: 2px;
    background-color: var(--bg-color);
    color: var(--color-color);
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      padding: 0;
    }
  }
  span {
    padding: 0 0.02rem;
  }
}
@media (min-width: 700px) {
  .Countdown {
    height: 41.0625px;

    font-size: 28px;

    div {
      padding: 0 7.46667px;
      span {
        padding: 0;
      }
    }
    span {
      padding: 0 3.73333px;
    }
  }
}
</style>
