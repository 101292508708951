import { createAppKit, useAppKit } from "@reown/appkit/vue";
import { EthersAdapter } from "@reown/appkit-adapter-ethers";
import { defineChain } from "@reown/appkit/networks";
import { bscTestnet, bsc } from "@reown/appkit/networks";

// 1. Get projectId from https://cloud.reown.com
const projectId = "5f931bf56115df8e871c07ad48050535";

// 2. Create your application's metadata object
const metadata = {
  name: "Starship-Planet on Chain",
  description: "Starship-Planet on Chain",
  url: "https://x.starship.red/", // url must match your domain & subdomain
  icons: ["https://x.starship.red/favicon.ico"],
};

// Define the custom network
const customNetwork = defineChain({
  id: 56,
  caipNetworkId: "eip155:56",
  chainNamespace: "eip155",
  name: "BscScan",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "BNB",
  },
  rpcUrls: {
    default: {
      http: [
        "https://bscrpc.com",
        "https://rpc.ankr.com/bsc",
        "https://bsc-dataseed1.ninicoin.io",
        "https://bsc-dataseed4.defibit.io",
        "https://bsc-dataseed3.ninicoin.io",
        "https://bsc-dataseed1.defibit.io",
        "https://bsc-dataseed2.ninicoin.io",
        "https://bsc-dataseed4.ninicoin.io",
        "https://rpc-bsc.bnb48.club/",
      ],
    },
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://bscscan.com" },
  },
});

// 3. Create a AppKit instance
createAppKit({
  adapters: [new EthersAdapter()],
  networks: [customNetwork],
  metadata,
  projectId,
  enableCoinbase: false,
  features: {
    onramp: false,
    swaps: false,
    analytics: false, // Optional - defaults to your Cloud configuration
    email: false, // default to true
    socials: false,
    emailShowWallets: true, // true为折叠
    // socials: ["google", "x", "apple", "facebook", "discord"],
  },
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", //mask
    "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662", //BIT
    "e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b", //qur
    "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662", //BIT
    "20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66", //TP
  ],
});

// 4. Use modal composable
const modal = useAppKit();

export { modal };
