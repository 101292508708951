export default {
  NoData: "データなし",
  networkdelay: "遅延、後で再試行",
  index: {
    fleetrevenue: "収益",
    Issue: "{number}回",
    starship: "艦",
    Notturnedon: "未起動",
    remainingstarships: "残り",
    Starshipssold: "販売済",
    Totalstarships: "総数",
    launchpreparation: "準備中",
    mystarship: "私の艦",
    inprogress: "進行中",
    ended: "終了",
    Refunded: "返金済",
    unknownstatus: "不明",
    Launchsuccessful: "成功",
    launchfailed: "失敗",
    Exceedingthemaximumlimit: "上限超過",
    Outoflevel: "レベル外",
    Donationcardexpired: "カード期限切れ",
    Notwithinparticipationtime: "時間外",
    Exceededmaximumsupply: "供給超過",
    Exceededthemaximumnumberofminers: "マイナー超過",
  },
  car: {
    Numberofstarshipssold: "販売数",
    Numberofstarshipslaunched: "発射数",
    Mystarshipnumber: "番号",
  },
  deta: {
    starshiptrajectory: "軌道",
    Successfullylaunched: "成功",
    launchtime: "時間",
    Purchaselimit: "制限",
    ships: "隻",
    Successstarshipnumber: "成功番号",
    myearnings: "収益",
    fleetrevenue: "収益",
    Availableforcollection: "受取可",
    Notinvolved: "未参加",
    staticincome: "固定収益",
    Numberofrecyclablestarships: "回収数",
    recyclable: "回収可",
    Successfullylaunchedaccount: "成功アカウント",
    Receivefleetbenefits: "報酬受取",
    Recoverstarship: "回収",
    Receivedsuccessfully: "受取成功",
    Recyclingsuccessful: "回収成功",
    Received: "受取済",
  },
  min: {
    Miningoutputcoefficient: "係数",
    Completeddestruction: "破壊済",
    Myminingmachine: "採掘機",
    Miningmachinenumber: "{number}号",
    Mining: "採掘中",
    Out: "退出",
    MiningtotalMarsvalue: "総火星値",
    MiningmachinetotalMarsvalue: "残火星値",
    Currentlyavailableforcollection: "受取可{name}",
    Estimatedfuelcost: "ガス料",
    MinedSTAS: "採掘済STAS",
    Minecoolingperiod: "冷却",
    Withdrawearnings: "引出",
    Extractionsuccessful: "成功",
  },
  my: {
    Bindcaptainaddress: "バインド",
    binding: "バインド",
    Myfleetdirectlypushesthenumberofpeople: "直推",
    Invitefriends: "招待",
    Expired: "期限切れ",
    level: "レベル",
    maturity: "満期",
    Participateinhistory: "履歴",
    Buyitnow: "購入",
    Copiedsuccessfully: "コピー成功",
    Copyfailed: "コピー失敗",
    Pleaseenterthecorrectwalletaddress: "正しいアドレス入力",
    Bindingsuccessful: "成功",
    Purchasesuccessful: "成功",
  },
};
