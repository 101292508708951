import axios from "axios";
import { ethers } from "ethers";
import gameABI from "@/assets/gameABI.json";
import { getprovider } from "@/api/wallet";
import { message } from "ant-design-vue";

import i18n from "@/lang/i18n";

export const Periodassistance = async (ele, address, isConnected) => {
  try {
    const response = await axios.get("/Setting.json");
    const config = response.data;

    let walletProvider = config.switch ? config.BSCRPC : config.TESTNETRPC;
    const provider = new ethers.JsonRpcProvider(walletProvider);

    const Contract = new ethers.Contract(ele, gameABI, provider);

    // 获取全局数据
    let roominfo = await Contract.get_gameData();
    const originalArray = [...roominfo];
    originalArray[0] = ethers.formatUnits(originalArray[0]);

    let info = [];
    if (isConnected) {
      // 获取玩家数据
      const playerData = await Contract.get_playerData(address);
      info = [...playerData];
      info[5] = ethers.formatUnits(info[5]);
      info[6] = ethers.formatUnits(info[6]);
    }

    return [...originalArray, ...info];
  } catch (error) {
    console.error("Periodassistance error:", error);
    return [];
  }
};

export const errorinfo = async (error) => {
  if (error.info && error.info.error) {
    let msg = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;
    message.error(msg);
  } else {
    message.error(i18n.global.t("networkdelay"));
  }
  console.log(error);
};

export const formatLargeNumber = (amount) => {
  // 确保输入为数字
  amount = Number(amount);

  // 如果输入无效，返回原值
  if (isNaN(amount)) {
    return amount; // 或者抛出错误 throw new Error("Invalid number");
  }

  // 大于100万，用M表示
  if (amount >= 1_000_000) {
    return (amount / 1_000_000).toFixed(2) + "M";
  } else if (amount >= 100_000) {
    return (amount / 1_000).toFixed(2) + "K";
  } else {
    return amount.toFixed(2);
  }
};

export const gaslimt = async (address, abi, fun, parint = []) => {
  try {
    const provider = await getprovider().getSigner();
    const Contract = new ethers.Contract(address, abi, provider);
    const txs = {
      to: address,
      data: Contract.interface.encodeFunctionData(fun, [...parint]),
    };
    const gasLimit = await provider.estimateGas(txs);
    return Math.floor(Number(gasLimit) * 1.2).toString();
  } catch (error) {
    console.log(error);
    return "1000000";
  }
};
