<template>
  <div class="home">
    <div class="income">
      <div>
        {{ isActive == 1 ? formatLargeNumber(roominfoone.commissionAmount) : isActive == 2 ? formatLargeNumber(roominfotwo.commissionAmount) : formatLargeNumber(roominfothree.commissionAmount) }}
      </div>
      <div class="fleetrevenue">
        <a-avatar size="small" :src="require('@/assets/img/60.png')"></a-avatar>
        <div>{{ $t("index.fleetrevenue") }}</div>
      </div>
      <div :class="stasimg">
        <a-image :preview="false" :src="require('@/assets/img/Picture1.png')" />
      </div>
    </div>
    <div class="Period">
      <div class="countdownleft">
        <div>{{ $t("index.Issue", { number: Period }) }}</div>
        <div v-if="roominfoone?.endTime" style="--bg-color: var(--stas-color); --color-color: var(--stas-car)">
          <Countdownview :key="countdownKey" :message="roominfoone.endTime * 1000" />
        </div>
      </div>
      <span class="text">
        {{ getRoomStatusText(isActive) }}
      </span>
    </div>
    <div class="Buttonlist">
      <div
        v-for="(room, index) in [roominfoone, roominfotwo, roominfothree]"
        :key="index"
        :class="{ active: isActive === index + 1 }"
        @click="room.ticket ? handleRoomClick(index + 1, room) : Notturnedon()"
      >
        <span v-if="room.ticket">{{ $t("index.starship") + room.ticket }}</span>
        <span v-else>{{ $t("index.Notturnedon") }}</span>
      </div>
    </div>
    <div class="car">
      <div class="top"></div>
      <div class="stastag">
        <span>{{ isActive == 1 ? $t("index.starship") + "100" : isActive == 2 ? $t("index.starship") + "300" : $t("index.starship") + "500" }}</span>
      </div>
      <a-divider style="border-color: var(--stas-border)" dashed />
      <div class="left"></div>
      <div class="right"></div>
      <div class="xjinfo" style="flex: 1">
        <div>
          <span>{{ getRemainingTickets(isActive) }}</span>
          <span>{{ $t("index.remainingstarships") }}</span>
        </div>
        <div>
          <span>{{ getSoldTickets(isActive) }}</span>
          <span>{{ $t("index.Starshipssold") }}</span>
        </div>
        <div>
          <span>{{ getTotalTickets(isActive) }}</span>
          <span>{{ $t("index.Totalstarships") }}</span>
        </div>
      </div>
      <div class="buy">
        <div class="stasinput">
          <a-input-number :controls="false" v-model:value="nubme" disabled :min="1" :max="10" @input="handleChange">
            <template #addonBefore>
              <div @click="nubme = nubme < 10 ? nubme + 1 : nubme">
                <PlusOutlined />
              </div>
            </template>

            <template #addonAfter>
              <div @click="nubme = nubme > 1 ? nubme - 1 : nubme">
                <MinusOutlined />
              </div>
            </template>
          </a-input-number>
        </div>
        <div class="stasbutton">
          <a-button size="large" :loading="loading" :disabled="isbuy" type="primary" @click="buy()">{{ $t("index.launchpreparation") }}</a-button>
        </div>
      </div>
    </div>
    <div class="divider">
      <span>—— {{ $t("index.mystarship") }} ——</span>
    </div>
    <div class="carlist">
      <div v-for="(room, index) in [roominfoone, roominfotwo, roominfothree].slice(0, grade)" :key="index">
        <span>{{ $t("index.starship") + room.ticket }}</span>
        <div v-if="room.tickets?.length > 0" class="infolist">
          <div v-for="(item, idx) in room.tickets" :key="idx">
            <span>{{ item }}</span>
          </div>
        </div>
        <span v-else>{{ $t("NoData") }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { toRefs, computed, ref, reactive, onMounted, watch, getCurrentInstance, onUnmounted } from "vue";
import Countdownview from "@/components/Countdownview";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";
import axios from "axios";
import { useStore } from "vuex";
import { ethers } from "ethers";
import FactoryABI from "@/assets/FactoryABI.json";
import gameABI from "@/assets/gameABI.json";
import { getprovider } from "@/api/wallet";

import { showLoading, hideLoading } from "@/api/loadingStore";
import { modal } from "@/api/web3ModalConfig";
import { Periodassistance, errorinfo, formatLargeNumber, gaslimt } from "@/api/hook";
import { useRoute } from "vue-router";

import { useAppKit, useAppKitAccount } from "@reown/appkit/vue";
const accountData = useAppKitAccount();
const { address, caipAddress, status, isConnected } = toRefs(accountData.value);
const { open, close } = useAppKit();
const countdownKey = ref(1);
const route = useRoute();
const store = useStore();
const Superior = computed(() => store.state.Superior);
const stasimg = ref("stasimg");

const { proxy } = getCurrentInstance();
const grade = ref(1);
// const countdownRef = ref(null);
const nubme = ref(1);
const isActive = ref(1);
const Period = ref(0);
const Factoryco = ref(null);
const USDTaddress = ref(null);
const loading = ref(false);
const walletProvider = ref(null);
const buyaddress = ref(null);
const unitprice = ref(0);
const examine = ref(false);
const isbuy = ref(true);
const roominfoone = reactive({
  ticket: 0,
  period: 0,
  startTime: 0,
  endTime: 0,
  drawTime: 0,
  ticketSupply: 0,
  maxHold: 0,
  ticketsSold: 0,
  drawNumbers: [],
  winnerList: [],
  state: 0,
  tickets: [], //玩家已购买的号
  winningNumbers: [], //玩家已中奖的号码
  hasWon: false, //玩家是否有中奖的号码
  canPlayerClaimReward: false, //玩家是否有资格领奖
  canPurchase: false, //是否有资格购买门票
  commissionAmount: 0, //玩家可以领取的佣金数量
  address: null,
});
const roominfotwo = reactive({
  period: 0,
  startTime: 0,
  endTime: 0,
  drawTime: 0,
  ticketSupply: 0,
  maxHold: 0,
  ticketsSold: 0,
  drawNumbers: [],
  winnerList: [],
  state: 0,
  tickets: [], //玩家已购买的号
  winningNumbers: [], //玩家已中奖的号码
  hasWon: false, //玩家是否有中奖的号码
  canPlayerClaimReward: false, //玩家是否有资格领奖
  canPurchase: false, //是否有资格购买门票
  commissionAmount: 0, //玩家可以领取的佣金数量
  address: null,
});
const roominfothree = reactive({
  period: 0,
  startTime: 0,
  endTime: 0,
  drawTime: 0,
  ticketSupply: 0,
  maxHold: 0,
  ticketsSold: 0,
  drawNumbers: [],
  winnerList: [],
  state: 0,
  tickets: [], //玩家已购买的号
  winningNumbers: [], //玩家已中奖的号码
  hasWon: false, //玩家是否有中奖的号码
  canPlayerClaimReward: false, //玩家是否有资格领奖
  canPurchase: false, //是否有资格购买门票
  commissionAmount: 0, //玩家可以领取的佣金数量
  address: null,
});

const Notturnedon = async () => {
  proxy.$message.error(proxy.$t("index.Notturnedon"));
  return;
};
// 用这里来设置
const handleRoomClick = (index, room) => {
  isActive.value = index;
  buyaddress.value = room.address;
  unitprice.value = room.ticket;

  const currentTime = new Date();
  // 必须没过期   可购数量不能等于0  选择的购买数量  不能大于剩余数量
  if (currentTime < room.endTime * 1000 && nubme.value <= room.ticketSupply) {
    isbuy.value = false;
  } else {
    isbuy.value = true;
  }

  // countdownRef.value.startCountdown(room.endTime * 1000);
};

const handleChange = async () => {
  const roomInfos = [roominfoone, roominfotwo, roominfothree];
  const room = roomInfos[isActive.value - 1] || roominfoone;
  await handleRoomClick(isActive.value, room);
};
const getRoomStatus = (room) => {
  const statusMap = {
    0: "·" + proxy.$t("index.inprogress") + "·",
    1: "·" + proxy.$t("index.ended") + "·",
    2: "·" + proxy.$t("index.Refunded") + "·",
  };
  const timestamp = Date.now();
  if (timestamp > roominfoone.endTime * 1000 && room.state == 0) {
    return proxy.$t("index.ended");
  } else {
    return statusMap[room.state] || proxy.$t("index.unknownstatus");
  }
};

const getRoomStatusText = (activeIndex) => {
  const rooms = [roominfoone, roominfotwo, roominfothree];
  return getRoomStatus(rooms[activeIndex - 1]);
};

const getRemainingTickets = (activeIndex) => {
  const rooms = [roominfoone, roominfotwo, roominfothree];
  return rooms[activeIndex - 1].ticketSupply - rooms[activeIndex - 1].ticketsSold;
};

const getSoldTickets = (activeIndex) => {
  const rooms = [roominfoone, roominfotwo, roominfothree];
  return rooms[activeIndex - 1].ticketsSold;
};

const getTotalTickets = (activeIndex) => {
  const rooms = [roominfoone, roominfotwo, roominfothree];
  return rooms[activeIndex - 1].ticketSupply;
};

const getjson = async () => {
  try {
    const response = await axios.get("/Setting.json");
    const config = response.data;
    Factoryco.value = config.FactoryContract;
    USDTaddress.value = config.USDT;
    walletProvider.value = config.switch ? config.BSCRPC : config.TESTNETRPC;
  } catch (error) {
    console.log(error);
  }
};

const setRoomInfo = (room, roomInfo) => {
  roomInfo.ticket = Number(room[0]);
  roomInfo.period = Number(room[1]);
  roomInfo.startTime = Number(room[2]);
  roomInfo.endTime = Number(room[3]);

  const currentTime = new Date();
  if (currentTime < roomInfo.endTime * 1000) {
    isbuy.value = false;
  }

  roomInfo.drawTime = Number(room[4]);
  roomInfo.ticketSupply = Number(room[5]);
  roomInfo.maxHold = Number(room[6]);
  roomInfo.ticketsSold = Number(room[7]);
  roomInfo.drawNumbers = room[8];
  roomInfo.winnerList = room[9];
  roomInfo.state = Number(room[10]);
  roomInfo.tickets = room[11] ? room[11] : []; // 玩家已购买的号
  roomInfo.winningNumbers = room[12] ? room[12] : []; // 玩家已中奖的号码
  roomInfo.hasWon = room[13] ? room[13] : false; // 玩家是否有中奖的号码
  roomInfo.canPlayerClaimReward = room[14] ? room[14] : false; // 玩家是否有资格领奖
  roomInfo.canPurchase = room[15] ? room[15] : false; // 是否有资格购买门票
  roomInfo.commissionAmount = room[16] ? room[16] : 0; // 玩家可以领取的佣金数量
};

const Components = async () => {
  try {
    // The Contract object

    let Address = "0x0000000000000000000000000000000000000000";
    if (address.value) {
      Address = address.value;
    }

    let provider = new ethers.JsonRpcProvider(walletProvider.value);
    if (isConnected.value) {
      console.log(isConnected.value);
      provider = await getprovider();
    }

    const Contract = new ethers.Contract(Factoryco.value, FactoryABI, provider);
    // const Contract = new ethers.Contract(Factoryco.value, FactoryABI, await getprovider().getSigner());

    const datalist = await Contract.get_gameDatas();
    Period.value = Number(datalist);

    if (Period.value > 0) {
      const datalist = await Contract.get_RoomsByID(Period.value);

      grade.value = datalist.length;
      let room = await Periodassistance(datalist[0], Address, isConnected.value);
      setRoomInfo(room, roominfoone);
      roominfoone.address = datalist[0];

      if (datalist.length > 1) {
        room = await Periodassistance(datalist[1], Address, isConnected.value);
        setRoomInfo(room, roominfotwo);
        roominfotwo.address = datalist[1];
      }

      if (datalist.length > 2) {
        room = await Periodassistance(datalist[2], Address, isConnected.value);
        setRoomInfo(room, roominfothree);
        roominfothree.address = datalist[2];
      }
    }
  } catch (error) {
    errorinfo(error);
    console.log(error);
  }
};
const isBuy = ref(false);
const buy = async () => {
  if (!isConnected.value) {
    open();
    return;
  }

  try {
    if (isBuy.value) {
      return;
    }
    loading.value = true;
    isBuy.value = true;
    const Buyaddress = buyaddress.value;
    const Nubme = nubme.value;
    const Unitprice = unitprice.value;

    let abi = [" function allowance(address owner, address spender) external view returns (uint256)"];
    let contract = new ethers.Contract(USDTaddress.value, abi, await getprovider().getSigner());

    let allowance = await contract.allowance(address.value, Buyaddress);
    const Amount = (BigInt(ethers.parseUnits(Unitprice.toString())) * BigInt(Nubme.toString())).toString();
    if (allowance < Amount) {
      // 授权
      abi = ["function approve(address spender, uint256 amount) external returns (bool)"];
      contract = new ethers.Contract(USDTaddress.value, abi, await getprovider().getSigner());

      let tx = await contract.approve(Buyaddress, Amount);
      await tx.wait();
    }
    contract = new ethers.Contract(Buyaddress, gameABI, await getprovider().getSigner());
    let gas = await gaslimt(Buyaddress, gameABI, "buyTickets", [Nubme.toString()]);
    let tx = await contract.buyTickets(Nubme.toString(), { gasLimit: gas });
    await tx.wait();

    proxy.$message.success(proxy.$t("index.Launchsuccessful"));
    isBuy.value = false;
    loading.value = false;
    Components();
  } catch (error) {
    isBuy.value = false;
    loading.value = false;
    let msg;
    if (error.info && error.info.error) {
      msg = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;
    } else {
      msg = error.message;
    }

    if (msg.includes("Exceeding the maximum limit")) {
      proxy.$message.error(proxy.$t("index.Exceedingthemaximumlimit"));
    } else if (msg.includes("Insufficient donation card level")) {
      proxy.$message.error(proxy.$t("index.Outoflevel"));
    } else if (msg.includes("Not within the donation validity period")) {
      proxy.$message.error(proxy.$t("index.Donationcardexpired"));
    } else if (msg.includes("game status is not open")) {
      proxy.$message.error(proxy.$t("index.Notturnedon"));
    } else if (msg.includes("Not within participation time")) {
      proxy.$message.error(proxy.$t("index.Notwithinparticipationtime"));
    } else if (msg.includes("Exceeding maximum supply")) {
      proxy.$message.error(proxy.$t("index.Exceededmaximumsupply"));
    } else if (msg.includes("Exceeding the maximum number of mining machines held")) {
      proxy.$message.error(proxy.$t("index.Exceededthemaximumnumberofminers"));
    } else {
      proxy.$message.error(msg);
    }

    console.log(error.message);
  }
};
let timer = null;
// 增加一个轮询器
const start = async () => {
  try {
    if (!examine.value) {
      examine.value = true;
      await getjson();
      await Components();

      const roomInfos = [roominfoone, roominfotwo, roominfothree];
      const room = roomInfos[isActive.value - 1] || roominfothree;

      await handleRoomClick(isActive.value, room);
      countdownKey.value++;
      examine.value = false;
    }
  } catch (error) {
    errorinfo(error);
    examine.value = false;
  }
};

watch(address, async (newChainId, oldChainId) => {
  if (!examine.value) {
    showLoading();
    await start();

    hideLoading();
    window.scrollTo({ top: 0, behavior: "instant" });
    stasimg.value = "centered";
    setTimeout(() => {
      stasimg.value = "stasimg";
    }, 1000);
  }
});

onMounted(async () => {
  // 父组件加载后，触发子组件方法

  if (!examine.value) {
    showLoading();
    await start();
    hideLoading();
    window.scrollTo({ top: 0, behavior: "instant" });
    stasimg.value = "centered";
    if (ethers.isAddress(route.params.address)) {
      store.commit("setSuperior", route.params.address);
    }

    if (window.Telegram?.WebApp?.initData) {
      let urlEncodedString = window.Telegram.WebApp.initData;
      const decodedString = decodeURIComponent(urlEncodedString);
      const params = new URLSearchParams(decodedString);
      const Address = params.get("start_param");
      if (ethers.isAddress(Address)) {
        store.commit("setSuperior", Address);
      }
    }
    setTimeout(() => {
      stasimg.value = "stasimg";
    }, 1000);
  }

  timer = setInterval(() => {
    start();
  }, 5000);
});
onUnmounted(() => {
  if (timer) clearInterval(timer);
});
</script>
<style lang="scss">
.home {
  /* background: red; */
  flex: 1;
  background-image: url("~@/assets/img/bg.png");
  background-size: 100% auto; /* 宽度覆盖，高度自适应 */
  background-repeat: no-repeat; /* 防止背景重复 */
  background-position: center calc(-0.2rem); /* 向上偏移 40px */
  .carlist {
    display: flex;
    flex-direction: column;
    gap: 5px;
    > div {
      background: var(--stas-car);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > span {
        color: var(--stas-color-yellow);
        font-size: 0.13rem;
        padding: 0.07rem 0;
      }
      > span:nth-of-type(2) {
        padding: 0 0 0.1rem;
        color: var(--stas-color-min);
      }
      .infolist {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; /* 允许子元素换行 */
        padding: 0 0.1rem 0.1rem;
        justify-content: center;
        gap: 0.06rem;
        > div {
          background-image: url("~@/assets/img/7442.png");
          background-size: 100% 100%; /* 宽度覆盖，高度自适应 */
          font-weight: 500;
          font-size: 0.1rem;
          width: 0.25rem;
          height: 0.28rem;
          color: var(--stas-button);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .divider {
    padding: 0.1rem;
    display: flex;
    justify-content: center;
    font-size: 0.15rem;
  }
  .Buttonlist {
    margin-top: 0.21rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      height: 0.3rem;
      background: rgba(253, 117, 79, 0.3);
      border-radius: 5px 5px 5px 5px;
      /* opacity: 0.3; */
      span {
        color: #fff;
        opacity: 1;
      }
    }
    .active {
      height: 0.36rem;
      background-color: rgba(0, 0, 0, 0); /* 完全透明 */
      background-image: url("~@/assets/img/7444.png");
      background-size: 100% 100%; /* 宽度覆盖，高度自适应 */
      padding-bottom: 0.06rem;
    }
  }
  .income {
    padding-top: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.03rem;
    font-size: 0.33rem;
    position: relative; /* 父元素设为相对定位 */
    .stasimg {
      position: absolute; /* 子元素绝对定位 */
      right: 0.4rem;
      top: 0.15rem;
      .ant-image-img {
        width: 1.3rem;
      }
    }

    .fleetrevenue {
      display: flex;
      flex-direction: row;
      /* justify-content: center; */
      align-items: center;
      gap: 0.02rem;
      font-size: 0.12rem;
    }
  }
  .Period {
    padding-top: 0.3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.24rem;
    .countdownleft {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.1rem;
    }
    .text {
      font-weight: 400;
      font-size: 0.16rem;
      line-height: 0px;
      color: var(--stas-color-yellow);
    }
  }
  .car {
    margin-top: 0.16rem;
    height: 2rem;
    background: var(--stas-car);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 0.24rem;
    position: relative; /* 父元素设为相对定位 */
    .stastag {
      position: absolute; /* 子元素绝对定位 */
      left: 0;
      color: var(--stas-color-yellow);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px 0 8px 0;
      background: rgba(253, 117, 79, 0.3);
      font-size: 0.13rem;
      height: 0.25rem;
      padding: 0 0.05rem;
    }
    .xjinfo {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      div {
        padding-top: 0.25rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        span:first-of-type {
          font-weight: 500;
          font-size: 0.22rem;
        }
        span:nth-of-type(2) {
          font-weight: 400;
          font-size: 0.13rem;
          color: var(--stas-color-min);
        }
      }
    }
    .buy {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.29rem;
      align-items: center;
      padding: 0 0.12rem;
      .stasinput {
        height: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-input-number-group-addon {
          width: 0.4rem;
          height: 0.4rem;
          font-size: 0.18rem;
          font-weight: bold;
          background-color: var(--stas-car);
          color: var(--stas-color);
          border: 1px solid var(--stas-border);
          padding: 0;
          > div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .ant-input-number-group-wrapper {
          height: 0.4rem;
        }
        .ant-input-number {
          width: 0.5rem;
          height: 0.4rem;
          font-size: 0.15rem;
          background-color: var(--stas-car);
          border-color: var(--stas-border);
          color: var(--stas-color) !important;
          display: flex;

          align-items: center;
          font-size: 0.15rem;
          text-align: center;
        }
        .ant-input-number-input {
          color: var(--stas-color) !important;
          font-size: 0.15rem;
          text-align: center;
        }
      }
      .stasbutton {
        flex: 1;
        .ant-btn.ant-btn-lg {
          height: 0.4rem;
          font-size: 0.16rem;
        }
        .ant-btn-primary {
          width: 100%;
          background-color: var(--stas-button);
          border-color: var(--stas-button);
        }

        .ant-btn-primary:not(:disabled):hover {
          color: #fff;
          background-color: var(--stas-hover);
        }
      }
    }

    .ant-divider {
      position: absolute; /* 子元素绝对定位 */
      top: 50%; /* 子元素的顶部对齐父元素的 50% */
    }
    .ant-divider-horizontal {
      margin: 0 0;
    }

    .top {
      position: absolute; /* 子元素设置为绝对定位 */
      top: -3px; /* 将子元素的顶部放到父元素上方 2px，吸附在外面 */
      left: 50%; /* 子元素居中对齐父元素 */
      transform: translateX(-50%); /* 水平居中对齐 */
      width: calc(100% - 0.26rem);
      height: 3px; /* 子元素高度设置为 2px */
      background-color: var(--stas-button); /* 子元素背景色设置为红色 */
      border-radius: 30px 30px 0 0;
    }
    .left {
      position: absolute; /* 子元素绝对定位 */
      top: 50%; /* 子元素的顶部对齐父元素的 50% */
      transform: translate(-50%, -50%); /* 将子元素的中心对齐父元素的中心 */
      width: 0.24rem;
      height: 0.24rem;
      border-radius: 50%;
      background-color: var(--stas-bg);
    }
    .right {
      position: absolute; /* 子元素绝对定位 */
      top: 50%; /* 子元素的顶部对齐父元素的 50% */
      right: 0; /* 子元素的右边对齐父元素的右边 */
      transform: translate(50%, -50%); /* 将子元素的中心对齐父元素的中心 */
      width: 0.24rem;
      height: 0.24rem;
      border-radius: 50%;
      background-color: var(--stas-bg);
    }
  }
}
@media (min-width: 700px) {
  .home {
    background-position: center calc(-37.33px); /* 向上偏移 40px */

    .carlist {
      > div {
        > span {
          font-size: 24.2667px;
          padding: 13.0667px 0;
        }
        > span:nth-of-type(2) {
          padding: 0 0 18.6667px;
        }
        .infolist {
          padding: 0 18.6667px 18.6667px;

          gap: 11.2px;
          > div {
            font-size: 18.6667px;
            width: 46.6562px;
            height: 52.2656px;
          }
        }
      }
    }

    .divider {
      padding: 18.6667px;

      font-size: 28px;
    }
    .income {
      padding-top: 56px;
      gap: 5.6px;
      font-size: 61.6px;
      .fleetrevenue {
        gap: 3.733px;
        font-size: 22.4px;
      }

      .stasimg {
        right: 74.6562px;
        top: 28px;
        .ant-image-img {
          width: 242.66px;
        }
      }
    }
    .Period {
      padding-top: 56px;

      gap: 18.6667px;
      font-size: 44.8px;

      .countdownleft {
        gap: 18.6667px;
      }
      .text {
        font-size: 29.8667px;
      }
    }
    .Buttonlist {
      margin-top: 39.2px;
      div {
        height: 56px;
      }
      .active {
        height: 67.19px;

        padding-bottom: 11.2px;
      }
    }
    .car {
      margin-top: 29.8667px;
      height: 373.328px;
      gap: 44.8px;
      .left,
      .right {
        width: 44.8px;
        height: 44.8px;
      }

      .stastag {
        font-size: 24.2667px;
        height: 46.6562px;
        padding: 0 9.33333px;
      }

      .xjinfo {
        div {
          padding-top: 46.6562px;
          span:first-of-type {
            font-size: 41.0667px;
          }
          span:nth-of-type(2) {
            font-size: 24.2667px;
          }
        }
      }

      .top {
        width: 595.45px;
      }
      .buy {
        gap: 54.1333px;
        padding: 0 22.4px;
        .stasinput {
          height: 74.6562px;
          .ant-input-number-group-addon {
            font-size: 33.6px;
            width: 74.6562px;
            height: 74.6562px;
          }
          .ant-input-number-group-wrapper {
            height: 74.6562px;
          }
          .ant-input-number {
            width: 93.3281px;
            height: 74.6562px;
            font-size: 28px;
          }
          .ant-input-number-input {
            font-size: 28px;
          }
        }
        .stasbutton {
          flex: 1;
          .ant-btn.ant-btn-lg {
            height: 74.6562px;
            font-size: 29.8667px;
          }
        }
      }
    }
  }
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100px;
  height: 100px;
  animation: move-to-top 1s ease-in-out forwards; /* 定义动画 */
}

@keyframes move-to-top {
  0% {
    top: 50%; /* 起始位置：屏幕中间 */
    transform: translate(-50%, -50%);
  }
  100% {
    top: 0.75rem; /* 结束位置：距离顶部10px */
    transform: translate(-0.001%, 0); /* 修正水平居中 */
    width: 1.3rem;
  }
}

@media (min-width: 700px) {
  @keyframes move-to-top {
    0% {
      top: 50%; /* 起始位置：屏幕中间 */
      transform: translate(-50%, -50%);
    }
    100% {
      top: 140px; /* 结束位置：距离顶部140px */

      transform: translate(-0.001%, 0); /* 修正水平居中 */
      width: 230px;
    }
  }
}

@keyframes stasimg {
  0% {
    transform: translateY(0); /* 初始位置 */
  }
  25% {
    transform: translateY(-10px); /* 向上抖动 */
  }
  50% {
    transform: translateY(0); /* 恢复原位 */
  }
  75% {
    transform: translateY(10px); /* 向下抖动 */
  }
  100% {
    transform: translateY(0); /* 恢复原位 */
  }
}

.stasimg {
  display: inline-block;
  animation: stasimg 1s ease-in-out 0s infinite; /* 动画持续1秒，反复循环 */
}
</style>
