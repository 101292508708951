import { createStore } from "vuex";

export default createStore({
  state: {
    Superior: "",
  },
  mutations: {
    setSuperior(state, value) {
      state.Superior = value;
    },
  },
});
