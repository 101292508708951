import { useAppKitProvider } from "@reown/appkit/vue";
import { BrowserProvider } from "ethers";

// import { ElMessage } from 'element-plus'

function getprovider() {
  let provider = null;

  try {
    const { walletProvider } = useAppKitProvider("eip155");
    provider = new BrowserProvider(walletProvider);
  } catch (error) {
    console.log(error);
  }
  return provider;
}

export { getprovider };
