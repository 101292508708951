export default {
  NoData: "Sin datos",
  networkdelay: "Retraso en la red",
  index: {
    fleetrevenue: "Ingresos",
    Issue: "Ronda {number}",
    starship: "Nave",
    Notturnedon: "No activado",
    remainingstarships: "Restantes",
    Starshipssold: "Vendidas",
    Totalstarships: "Total",
    launchpreparation: "Preparación",
    mystarship: "Mi nave",
    inprogress: "En curso",
    ended: "Finalizado",
    Refunded: "Reembolsado",
    unknownstatus: "Desconocido",
    Launchsuccessful: "Éxito",
    launchfailed: "Fallido",
    Exceedingthemaximumlimit: "Límite superado",
    Outoflevel: "Fuera de nivel",
    Donationcardexpired: "Caducada",
    Notwithinparticipationtime: "Fuera de tiempo",
    Exceededmaximumsupply: "Exceso de oferta",
    Exceededthemaximumnumberofminers: "Exceso de mineros",
  },
  car: {
    Numberofstarshipssold: "Vendidas",
    Numberofstarshipslaunched: "Lanzadas",
    Mystarshipnumber: "Mi número",
  },
  deta: {
    starshiptrajectory: "Trayectoria",
    Successfullylaunched: "Éxito",
    launchtime: "Hora",
    Purchaselimit: "Límite",
    ships: "Naves",
    Successstarshipnumber: "Nave exitosa",
    myearnings: "Ganancias",
    fleetrevenue: "Ingresos",
    Availableforcollection: "Disponible",
    Notinvolved: "No participó",
    staticincome: "Estático",
    Numberofrecyclablestarships: "Reciclables",
    recyclable: "Reciclable",
    Successfullylaunchedaccount: "Éxito",
    Receivefleetbenefits: "Beneficios",
    Recoverstarship: "Recuperar",
    Receivedsuccessfully: "Recibido",
    Recyclingsuccessful: "Reciclado",
    Received: "Recibido",
  },
  min: {
    Miningoutputcoefficient: "Coef. minería",
    Completeddestruction: "Destrucción",
    Myminingmachine: "Mi máquina",
    Miningmachinenumber: "Máquina {number}",
    Mining: "Minando",
    Out: "Fuera",
    MiningtotalMarsvalue: "Valor total",
    MiningmachinetotalMarsvalue: "Valor restante",
    Currentlyavailableforcollection: "Disponible {name}",
    Estimatedfuelcost: "Costo gas",
    MinedSTAS: "STAS minados",
    Minecoolingperiod: "Enfriamiento",
    Withdrawearnings: "Retirar",
    Extractionsuccessful: "Retiro exitoso",
  },
  my: {
    Bindcaptainaddress: "Vincular capitán",
    binding: "Vinculando",
    Myfleetdirectlypushesthenumberofpeople: "Reclutamiento",
    Invitefriends: "Invitar",
    Expired: "Caducado",
    level: "Nivel",
    maturity: "Vencimiento",
    Participateinhistory: "Historial",
    Buyitnow: "Comprar",
    Copiedsuccessfully: "Copiado",
    Copyfailed: "Error",
    Pleaseenterthecorrectwalletaddress: "Dirección válida",
    Bindingsuccessful: "Vinculado",
    Purchasesuccessful: "Compra exitosa",
  },
};
