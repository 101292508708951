<template>
  <a-spin :spinning="loadingState" :indicator="customIcon" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
    <div class="coment">
      <HeaderView />
      <router-view />
      <FooterView />
    </div>
  </a-spin>
</template>
<script setup>
import { h, ref, onMounted } from "vue";
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
import logo from "@/assets/img/Picture1.png"; // 引入图片
import { loadingState } from "@/api/loadingStore";
import { RedoOutlined } from "@ant-design/icons-vue";
import axios from "axios";

// 自定义图片作为加载图标

const indicator = h(RedoOutlined, {
  style: {
    fontSize: "32px",
    color: "#662606",
  },
});
const customIcon = h("div", { style: { textAlign: "center" } }, [
  h("img", {
    src: logo, // 图片路径
    alt: "loading",
    style: {
      width: "100px", // 图片宽度
      height: "100px", // 图片高度
      animation: "stasimg 1s ease-in-out 0s infinite",
    },
  }),
  h(
    "p",
    {
      style: {
        marginTop: "10px", // 图片和文字间的间距
        color: "var(--stas-color-yellow)", // 文字颜色
        fontSize: "14px", // 文字大小
      },
    },
    "Loading..."
  ),
]);

if (window.Telegram?.WebApp) {
  window.Telegram.WebApp.setHeaderColor("#662606");
  window.Telegram.WebApp.setBackgroundColor("#662606");
  window.Telegram.WebApp.disableVerticalSwipes();
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
html,
body {
  box-sizing: border-box;
  font-size: calc(100vw / 3.75);
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  min-height: 100%;
  height: auto;
}
a {
  all: unset; /* 清除所有样式 */
  text-decoration: none; /* 移除下划线 */
  color: #ffffff; /* 使用父元素的颜色 */
}
/* a:hover{

} */
:root {
  --stas-bg: #662606;
  --stas-button: #fd754f;
  --stas-car: #401b10;
  --stas-border: #664940;
  --stas-color-bg: #a08d88;
  --stas-color: #ffffff;
  --stas-hover: #fd754f;
  --stas-color-min: #b3a49f;
  --stas-color-yellow: #ffff00;
  --stas-bordee-car: #5b3b32;
}

.ant-pagination .ant-pagination-item-active:hover a,
.ant-pagination .ant-pagination-item-active a {
  color: var(--stas-color);
}
body {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  font-size: 0.0375rem;
  user-select: none;
}
div {
  line-height: normal;
}
.coment {
  display: flex;
  position: relative;
  width: 100vw;
  font-size: 0.16rem;
  min-height: 100vh;
  color: aliceblue;
  background: var(--stas-bg);
  > div:nth-of-type(2) {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-left: 0.15rem;
    padding-right: 0.15rem;
  }
}

.ant-pagination {
  color: var(--stas-color);
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-disabled,
  .ant-pagination-disabled:hover {
    > a,
    .ant-pagination-item-link {
      color: var(--stas-color);
      background-color: var(--stas-car);
    }
  }
  .ant-pagination-options {
    .ant-pagination-options-quick-jumper {
      > input {
        background-color: var(--stas-bg);
        border-color: var(--stas-button);
        color: var(--stas-color);
      }
    }
  }
  .ant-pagination-item-active {
    border: 0;
    background: none;
    > a {
      background-color: var(--stas-button);
    }
  }
}
.ant-spin {
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
}

.ant-spin-nested-loading {
  background: var(--stas-bg) !important;
}
.ant-spin-container {
  background: rgb(51, 54, 57) !important;
}
.ant-spin-blur {
  z-index: 99;
  opacity: 0 !important; /* 越小透明度越低 */
}
.ant-spin-dot {
  position: unset !important; /* 清除 position */
  top: unset !important; /* 清除 top */

  /* position: absolute !important;
    top: 40% !important; */
}

.ant-spin .ant-spin-dot {
  position: unset !important; /* 清除 position */
  top: unset !important;
  width: auto;
  height: auto;
}

.ant-btn-default:disabled,
.ant-btn-primary:disabled {
  background: #b24d2a !important;
  border-color: #b24d2a !important;
  color: #b39383 !important;
  opacity: 0.5;
  /* background: #3c3742 !important; */
  /* border-color: #3c3742 !important; */
  /* color: #666171; */
}

@media (min-width: 700px) {
  .coment {
    width: 700px;
    margin: auto;
    font-size: 29.8667px;
    > div:nth-of-type(2) {
      padding: 112px 28px;
    }
  }
}

@keyframes stasimg {
  0% {
    transform: translateY(0); /* 初始位置 */
  }
  25% {
    transform: translateY(-10px); /* 向上抖动 */
  }
  50% {
    transform: translateY(0); /* 恢复原位 */
  }
  75% {
    transform: translateY(10px); /* 向下抖动 */
  }
  100% {
    transform: translateY(0); /* 恢复原位 */
  }
}
</style>
<!-- npx prettier --write . -->
