import { createI18n } from "vue-i18n";
import zh from "./zh";
import en from "./en";
import Korean from "./Korean";
import Japanese from "./Japanese";
import spanish from "./spanish";
import Arabic from "./Arabic";
const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en", // 回退语言
  messages: {
    zh,
    en,
    Korean,
    Japanese,
    spanish,
    Arabic,
  },
});
export default i18n;
