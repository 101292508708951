// utils/loadingStore.js
import { ref } from "vue";

// 全局状态
export const loadingState = ref(false);

// 显示 Loading
export const showLoading = () => {
  loadingState.value = true;
};

// 隐藏 Loading
export const hideLoading = () => {
  loadingState.value = false;
};
