export default {
  NoData: "لا بيانات",
  networkdelay: "تأخير الشبكة",
  index: {
    fleetrevenue: "إيرادات",
    Issue: "الجولة {number}",
    starship: "سفينة",
    Notturnedon: "غير مُفعل",
    remainingstarships: "المتبقية",
    Starshipssold: "المباعة",
    Totalstarships: "الإجمالي",
    launchpreparation: "التحضير",
    mystarship: "سفينتي",
    inprogress: "جارية",
    ended: "منتهية",
    Refunded: "مُستردة",
    unknownstatus: "غير معروفة",
    Launchsuccessful: "نجاح",
    launchfailed: "فشل",
    Exceedingthemaximumlimit: "تجاوز الحد",
    Outoflevel: "خارج المستوى",
    Donationcardexpired: "منتهية",
    Notwithinparticipationtime: "خارج الوقت",
    Exceededmaximumsupply: "تجاوز الإمداد",
    Exceededthemaximumnumberofminers: "تجاوز العدد",
  },
  car: {
    Numberofstarshipssold: "المباعة",
    Numberofstarshipslaunched: "المُطلقة",
    Mystarshipnumber: "رقمي",
  },
  deta: {
    starshiptrajectory: "المسار",
    Successfullylaunched: "نجاح",
    launchtime: "الوقت",
    Purchaselimit: "الحد",
    ships: "سفن",
    Successstarshipnumber: "رقم النجاح",
    myearnings: "أرباحي",
    fleetrevenue: "إيرادات",
    Availableforcollection: "متاح",
    Notinvolved: "غير مشارك",
    staticincome: "ثابت",
    Numberofrecyclablestarships: "القابلة للتدوير",
    recyclable: "قابل",
    Successfullylaunchedaccount: "حساب النجاح",
    Receivefleetbenefits: "استلام",
    Recoverstarship: "استرداد",
    Receivedsuccessfully: "تم الاستلام",
    Recyclingsuccessful: "نجاح التدوير",
    Received: "مستلم",
  },
  min: {
    Miningoutputcoefficient: "المعامل",
    Completeddestruction: "مُكتمل",
    Myminingmachine: "جهازي",
    Miningmachinenumber: "جهاز {number}",
    Mining: "تعدين",
    Out: "خارج",
    MiningtotalMarsvalue: "القيمة الإجمالية",
    MiningmachinetotalMarsvalue: "القيمة المتبقية",
    Currentlyavailableforcollection: "متاح {name}",
    Estimatedfuelcost: "تكلفة الوقود",
    MinedSTAS: "STAS المستخرج",
    Minecoolingperiod: "التهدئة",
    Withdrawearnings: "السحب",
    Extractionsuccessful: "تم السحب",
  },
  my: {
    Bindcaptainaddress: "ربط القبطان",
    binding: "جاري الربط",
    Myfleetdirectlypushesthenumberofpeople: "التجنيد",
    Invitefriends: "دعوة",
    Expired: "منتهي",
    level: "مستوى",
    maturity: "استحقاق",
    Participateinhistory: "السجل",
    Buyitnow: "شراء",
    Copiedsuccessfully: "نسخ ناجح",
    Copyfailed: "فشل النسخ",
    Pleaseenterthecorrectwalletaddress: "أدخل العنوان الصحيح",
    Bindingsuccessful: "تم الربط",
    Purchasesuccessful: "تم الشراء",
  },
};
