<template>
  <div class="header">
    <div class="logo">
      <a-avatar :src="imageSrc" />
      <a-select ref="select" v-model:value="value" @change="handleChange">
        <template #suffixIcon><CaretDownOutlined class="ant-select-suffix" /></template>
        <a-select-option value="en">English</a-select-option>
        <a-select-option value="zh">中文繁体</a-select-option>
        <a-select-option value="Korean">한국어</a-select-option>
        <a-select-option value="Japanese">日本語</a-select-option>
        <a-select-option value="spanish">Español</a-select-option>
        <a-select-option value="Arabic">العربية</a-select-option>
      </a-select>
    </div>

    <!-- <appkit-button /> -->
    <div v-if="!isConnected" class="Wallet" @click="open()">Connect Wallet</div>
    <div v-else class="onWallet" @click="open()">
      <div>
        <a-avatar :src="imagebnb" />
        <div>{{ `${bnbrBalance}bnb` }}</div>
      </div>
      <div>{{ address.slice(0, 4) + "..." + address.slice(-6) }}</div>
    </div>
  </div>
</template>
<script setup>
import { toRefs, ref, onMounted, watch, getCurrentInstance } from "vue";
import { CaretDownOutlined } from "@ant-design/icons-vue";
import imageSrc from "@/assets/img/logo.png";
import imagebnb from "@/assets/img/1839.png";
import { modal } from "@/api/web3ModalConfig";

import { ethers } from "ethers";
import { getprovider } from "@/api/wallet";

import { useAppKit, useAppKitAccount } from "@reown/appkit/vue";
const { proxy } = getCurrentInstance();
const { open, close } = useAppKit();

const accountData = useAppKitAccount();
const { address, caipAddress, status, isConnected } = toRefs(accountData.value);
const bnbrBalance = ref(0);
const value = ref("en");
const handleChange = (value) => {
  proxy.$i18n.locale = value;
};
const getBalance = async () => {
  const balance = await getprovider().getBalance(address.value);
  const etherBalance = ethers.formatEther(balance);
  bnbrBalance.value = Math.trunc(parseFloat(etherBalance) * 1000) / 1000;
};

const setlang = async () => {
  value.value = proxy.$i18n.locale;
};

watch(address, async (newChainId, oldChainId) => {
  if (isConnected.value) {
    getBalance();
  }
});
onMounted(async () => {
  setlang();
  if (isConnected.value) {
    getBalance();
  }
});
</script>
<style lang="scss">
.header {
  height: 0.6rem;
  position: absolute;
  top: 0;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.15rem;
  .logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .ant-avatar {
      height: 0.32rem;
      width: 0.32rem;
    }
    .ant-select {
      width: 0.92rem;

      .ant-select-selector {
        height: 0.32rem;
        font-size: 0.13rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-select:hover .ant-select-selector,
    .ant-select-selector,
    .ant-select-selection-item,
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
      background: transparent;
      border: 0 !important;
      transition: none;
      box-shadow: none !important;
      color: var(--stas-color);
    }
    .ant-select-arrow {
      color: var(--stas-color);
      font-size: 0.13rem;
    }
    .ant-select-option {
      background: transparent;
    }
  }

  .Wallet {
    border: 1px solid #fd754f;
    border-radius: 25px;
    padding: 5px 10px;
  }
  .onWallet {
    font-size: 0.12rem;
    background: var(--stas-button);
    display: flex;
    flex-direction: row;
    border-radius: 80px;
    text-transform: uppercase;
    padding: 2px;
    gap: 5px;
    .ant-avatar {
      height: 0.16rem;
      width: 0.16rem;
    }
    > div:first-of-type {
      display: flex;
      flex-direction: row;
      gap: 5px;
      padding: 5px 0 5px 5px;
      justify-content: center;
      align-items: center;
    }
    > div:nth-of-type(2) {
      border-radius: 80px;
      background: var(--stas-car);
      padding: 5px 10px;
    }
  }
}
@media (min-width: 700px) {
  .header {
    width: 700px;
    height: 112px;
    padding: 28px;

    .logo {
      .ant-avatar {
        height: 57.72px;
        width: 57.72px;
      }
      .ant-select {
        width: 171.719px;

        .ant-select-selector {
          height: 59.7188px;
          font-size: 24.2667px;
        }
      }

      .ant-select-arrow {
        font-size: 24.2667px;
      }
    }

    .onWallet {
      font-size: 22.4px;

      .ant-avatar {
        height: 27.8594px;
        width: 27.8594px;
      }
    }
  }
}
</style>
<style scoped>
:global(.ant-select-dropdown) {
  background-color: var(--stas-color);
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
}
:global(.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
  background: var(--stas-button) !important;
  color: var(--stas-color) !important;
}
</style>
