import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import { preventZoom } from "@/api/preventZoom";
import i18n from "@/lang/i18n";

import "ant-design-vue/dist/reset.css";
preventZoom();
createApp(App).use(i18n).use(Antd).use(store).use(router).mount("#app");
